$primary-color: #f41106;
$border-color: #ddd;
$focus-border-color: $primary-color;
$forgot-password-color: #f41106;
@import './variables';

.noSelect,
a,
img,
div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect:focus,
a:focus,
img:focus {
  outline: none !important;
}

.selected {
  border-bottom: 3px solid red;
  padding-bottom: 5px;
}

.order-list {
  .chakra-container {
    @media (max-width: 767px) {
      background-color: #f0f1f7 !important;
    }
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

body {
  #root {
    overflow: initial !important;
  }
  font-family: 'Inter', sans-serif !important;
  overflow-x: hidden !important;

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  hr {
    border-color: #ddd !important;
  }

  .text-info {
    color: rgb(var(--info));
  }

  top: 0 !important;

  #google_translate_element {
    span {
      display: none;
    }
  }

  .goog-te-gadget {
    background: none;

    select {
      outline: none;
      color: #000;
    }

    select::-ms-expand {
      border-radius: 3px;
      border: 1px solid #e4e4e4;

      height: 40px;
      width: 100%;
      font-size: 15px;
      padding: 0 1rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../img/icons/arrow-down.svg') no-repeat;
      background-color: #f7f8fa;
      background-size: 14px;
      background-position: calc(100% - 15px) 12px;
      background-repeat: no-repeat;
    }
  }

  .skiptranslate {
    iframe {
      display: none;
    }
  }

  .account-page {
  }

  .chakra-select__wrapper {
    .chakra-select__icon-wrapper {
      color: #f41106;
    }
  }

  .chakra-menu__menu-list {
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    padding: 25px;

    .chakra-menu__menuitem {
      margin: 10px 0 10px;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  #google_translate_element {
    span {
      display: none;
    }
  }

  .goog-te-gadget {
    background: none;
    color: transparent;
    height: 40px;

    select {
      margin: 0 !important;
      outline: none;
      color: #000;
      border-radius: 3px;
      border: 1px solid #e4e4e4;

      height: 40px;
      width: 100%;
      font-size: 15px;
      padding: 0 1rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../img/icons/arrow-down.svg') no-repeat;
      background-color: #f7f8fa;
      background-size: 14px;
      background-position: calc(100% - 15px) 12px;
      background-repeat: no-repeat;
    }
  }

  .skiptranslate {
    iframe {
      display: none;
    }
  }

  // .chakra-container {
  //   @media (max-width: 767px) {
  //     padding: 10px;
  //   }
  // }

  .chakra-tabs {
    .chakra-tabs__tablist {
      padding-bottom: 20px;
    }

    .chakra-tabs__tab {
      border: 0;
      font-size: 14px;
      font-weight: 600;
    }

    .chakra-tabs__tab[aria-selected='true'],
    .chakra-tabs__tab[data-selected] {
      color: rgb(244, 17, 6);
      background: #ffefee;
      border-radius: 8px;
    }
  }

  // .chakra-tabs {
  //   .chakra-tabs__tablist {
  //     padding-bottom: 20px;
  //   }

  //   .chakra-tabs__tab {
  //     border: 0;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }

  //   .chakra-tabs__tab[aria-selected='true'],
  //   .chakra-tabs__tab[data-selected] {
  //     color: rgb(244, 17, 6); /* Red text color */
  //     border-bottom: 5px solid rgb(244, 17, 6); /* 5px red border-bottom */
  //     border-radius: 0; /* Ensure border-radius doesn't affect bottom border */
  //   }

  //   .chakra-tabs__tab[aria-selected='true'] svg,
  //   .chakra-tabs__tab[data-selected] svg {
  //     color: rgb(244, 17, 6);
  //     background: rgb(214, 128, 123); /* Red background for the icon */
  //     border-radius: 50%; /* Optional: If you want the icon background to be rounded */

  //   }
  // }

  // .chakra-button {
  //   padding: 10px;
  //   background-color: $primary-color;
  //   border: none;
  //   border-radius: 5px;
  //   color: white;
  //   font-size: 18px;
  //   font-weight: 400;
  //   cursor: pointer;
  //   height: 45px;
  //   transition: background-color 0.3s;

  //   &:hover {
  //     background-color: $primary-color !important;
  //   }
  // }
  @include placeholder {
    color: var(--Text_Secondary, #7a7a7a);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}

.home-banner {
  .swiper-slide {
    img {
      border-radius: 16px;
      width: 96%;
      height: 316px;
      // object-fit: cover;
      margin: 0 auto;

      @media (max-width: 767px) {
        height: 140px;
      }
    }
  }

  .swiper-button-next {
    right: -8px;

    @media (max-width: 767px) {
      right: -15px;
    }
  }

  .swiper-button-prev {
    left: -8px;

    @media (max-width: 767px) {
      left: -15px;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: gray;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 12px solid #ffffff;
    font-size: 23px;
    height: 86px;
    width: 86px;
    min-width: 86px;

    @media (max-width: 767px) {
      height: 55px;
      width: 55px;
      min-width: 55px;
    }
  }
}

.deals-banner,
.deal-banner {
  .swiper-button-next {
    right: 12px;
    display: flex !important;
  }

  .swiper-button-prev {
    left: 12px;
    display: flex !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: gray;
    // background-color: rgba(240, 240, 240, 0.7);
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 4px solid #ffffff;
    font-size: 15px;
    font-weight: 600;
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-bottom: 15px;
    // opacity: 0.8;
  }

  // .swiper-slide {
  //   &:nth-child(1) {
  //     padding-left: 35px;
  //   }
  //   &:nth-child(4) {
  //     padding-right: 35px;
  //   }
  // }
}

.deal-banner {
  .swiper-pagination-bullet {
    background: #d9d9d9 !important;
    margin: 0 4px !important;
  }

  .swiper-pagination-bullet-active {
    background: #f41106 !important;
    width: 25px;
    border-radius: 5px;
  }

  .swiper-pagination {
    position: relative;
    bottom: -5px;
  }
}

body {
  .swiper-pagination-bullet {
    background: #d9d9d9;
    height: 10px;
    width: 10px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #f41106;
    width: 25px;
    border-radius: 5px;
  }

  .swiper-pagination {
    position: relative;
    bottom: -5px !important;
  }
}

.product-card {
  position: relative;

  .product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-icons {
    position: absolute;

    right: 1rem;

    .icon {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      cursor: pointer;
    }

    .wishlist {
      background-color: #ffdedc;
    }

    .view {
      background-color: rgba(38, 191, 148, 0.2);
      color: rgba(38, 191, 148, 1);
    }
  }
}

.box {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 0.813rem;
  color: rgb(var(--default-text-color));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.alert-primary {
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  border-color: rgb(var(--primary) / 0.1);
  background-color: rgb(var(--primary) / 0.1);
  color: rgb(var(--primary));
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  height: 6px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
  /* width of vertical scrollbar */
  background-color: #f5f5f5;

  @media (max-width: 767px) {
    height: 3px;
    /* height of horizontal scrollbar ← You're missing this */
    width: 3px;
    /* width of vertical scrollbar */
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.checkout-border {
  border: #ededed 1px solid;
  border-radius: 10px;
  margin-top: 10px;
}

.checkout-title-text {
  color: #7a7a7a;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 480px) {
  .commonFont_ship {
    font-size: 9px !important;
    max-width: 140px !important;
  }

  .commonF2_ship {
    font-size: 8px !important;
    max-width: 140px !important;
  }

  .gepCls_cart {
    margin-right: -10px;
    gap: 0 !important;
  }

  .resp_tbl_box .css-tucewo {
    gap: 10px !important;
  }

  .resp_tbl_box .css-mr6tye {
    max-width: 162px;
  }

  .resp_tbl_box .css-ccav0j {
    height: 55px;
    width: 67px;
  }

  .disp_max_none {
    display: none !important;
  }

  .res_tbl_tdx {
    width: 95% !important;
  }
}

@media (max-width: 767px) {
  .commonFont_ship {
    font-size: 13px !important;
    max-width: 162px !important;
  }

  .commonF2_ship {
    font-size: 10px !important;
    max-width: 260px !important;
  }

  //  .gepCls_cart{
  //    margin-right: -10px;
  //    gap: 0 !important;
  //  }

  .resp_tbl_box .css-mr6tye {
    max-width: 162px;
  }

  .resp_tbl_box .css-ccav0j {
    height: 55px;
    width: 67px;
  }

  .res_tbl_tdx {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .resp_tbl_box .css-mr6tye {
    max-width: 162px;
  }

  .resp_tbl_box .css-ccav0j {
    height: 55px;
    width: 67px;
  }

  .resp_tbl_box .css-13n17dq {
    flex-direction: column !important;
  }

  .contailer_xytl {
    width: 100% !important;
  }
}

// resonsive css in cart details view only for mobile screen css - start

.llt_cart_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.llt_cart_head_icon {
  display: flex;
  gap: 15px;
}

.llt_cart_heading h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}

.llt_cart_details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100vh - 250px);
  overflow: auto;
}

.llt_cart_one_img {
  border: 1px solid #e3e3e3;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 11px;
}

.llt_cart_img_bx {
  display: flex;
  align-items: center;
  gap: 7px;
}

.llt_cart_name_bx {
  display: flex;
  align-items: center;
  gap: 10px;
}

// .anvHide_overFlow {
//   scrollbar-width: thin;
// }

// .anvHide_overFlow::-webkit-scrollbar {
//   width: 4px;
// }

.anvHide_overFlow::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.anvHide_overFlow {
  // -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  // scrollbar-width: none;
  /* Firefox */
}

.anvHide_overFlow:hover::-webkit-scrollbar {
  display: block;
}

.anvHide_overFlow:hover {
  // -ms-overflow-style: auto;
  /* Internet Explorer and Edge */
  // scrollbar-width: thin;
  /* Firefox */
}

.anvHide_overFlow::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.llt_cart_name_bx p {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  min-width: 178px;
}

.llt_cart_name_bx img {
  width: 19px;
  height: 17px;
}

.llt_cart_size_clr span {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.llt_cart_price_bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.llt_cart_price_bx button {
  border: 1px solid #e4e4e4;
  background: #bebebe;
  width: 21px;
}

.llt_cart_price_bx span {
  display: inline-block;
  width: 21px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.llt_cart_price_bx h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.llt_cart_shipping_bx {
  display: flex;
  gap: 6px;
  align-items: center;
}

.llt_cart_shipping_bx span {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.cart-page-mobile {
  .bottom-navigation,
  .llt_cart_checkout_bx {
    z-index: 1401;
  }
}

.llt_cart_checkout_bx {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  position: fixed;
  bottom: 59px;
  left: 0;
  width: 100%;
  padding: 10px 15px;
}

.llt_cart_check_all {
  display: flex;
  gap: 9px;
  align-items: center;
}

.llt_cart_check_all h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.llt_cart_total_price {
  display: flex;
  align-items: center;
  gap: 18px;
}

.llt_cart_total_price h6 {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.llt_cart_total_price button {
  background: #f41106;
  border-radius: 4px;
  border: 1px solid #f41106;
  padding: 10px 13px;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #fff;
}

.chakra-modal__content-container::before {
  content: '';
  background-color: #464646;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.74;
  top: 0;
  left: 0;
}

.chakra-modal__content-container .chakra-slide {
  bottom: 121px !important;
}

.llt_cart_summary_bx {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;
}

.llt_card_price_priveiw {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.llt_cart_bx_sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.llt_cart_bx_sum span {
  color: #8a8a8a;
}

.llt_cart_fast_delivery {
  display: flex;
  align-items: center;
  gap: 10px;
}

.llt_cart_fast_delivery {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.llt_cart_privacy_rules {
  padding-left: 20px;
}

.llt_cart_privacy_rules p {
  color: #8a8a8a;
  font-size: 12px;
  padding-top: 10px;
}

.llt_cart_input_coupan {
  position: relative;
}

.llt_cart_input_coupan input {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #8a8a8a;
  border-radius: 6px;
}

.llt_cart_input_coupan button {
  padding: 10px 15px;
  border: 1px solid #000000;
  border-radius: 0px 6px 6px 0px;
  position: absolute;
  top: 0;
  right: 0;
}

.overall_pdg_md {
  padding: 10px;
}

.__mainCtrl .anvCategory {
  border-bottom: transparent !important;
}

.__mainCtrl {
  position: relative;
}

.__mainCtrl:before {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% + 20px);
  left: -10px;
  background: #ededed;
  bottom: 0;
  z-index: 9;
  @media (max-width: 767px) {
    width: 100%;
    left: 0;
    display: none;
  }
}

.right-component .addCart_btn:disabled {
  background-color: rgb(32, 32, 32) !important;
}

.seven_tinPd {
  .swiper-button-prev {
    left: 17px !important;
  }
  .swiper-button-next {
    right: 17px !important;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: gray;
    // background-color: rgba(240, 240, 240, 0.7);
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 4px solid #ffffff;
    font-size: 15px;
    font-weight: 600;
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-bottom: 15px;
    // opacity: 0.8;
  }
}

.name_sclr {
  font-size: 15px;
  font-weight: 700;
  padding-right: 10px;
  padding-top: 2px;
}

// share button

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 7px 14px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

a.icon_flexCls {
  display: flex;
  align-items: center;
  gap: 6px;
}

// share button

// mobile responsive share functionality css start

._mainDeskShare_div {
  display: flex;
  gap: 5px;
  padding: 10px 5px;
  margin-top: 5px;
}

._mainShare_div {
  padding: 1.25rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 10px;
  // justify-content: center;
}

._cmnSocial {
  flex: 0 0 calc(25% - 9px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
._cmnSocialDeskt {
  flex-grow: 0;
  flex-shrink: 0;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

._innrSocial {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  border-radius: 0.5rem;
  font-size: 40px;
  color: #fff;
}

._innrSocialDesktop {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  border-radius: 0.5rem;
  font-size: 22px;
  color: #fff;
}
._cmnSocialDeskt span {
  font-size: 12px;
}

._facebookShl ._innrSocialDesktop,._facebookShl ._innrSocial {
  background-color: #0076fb;
}
._whatappShl ._innrSocialDesktop,._whatappShl ._innrSocial {
  background-color: #25d366;
}
._twitterShl ._innrSocialDesktop,._twitterShl ._innrSocial {
  background-color: #000;
}
._linkedinSh ._innrSocialDesktop,._linkedinShl ._innrSocial {
  background-color: #0077b5;
}
._emailShl ._innrSocialDesktop,._emailShl ._innrSocial {
  background-color: #c1c1c1;
}
._telegreShl ._innrSocialDesktop,._telegreShl ._innrSocial {
  background-color: #0088cc;
}

._cmnSocial span {
  font-size: 12px;
  margin-top: 5px;
}

.copyLink_dv {
  background: rgb(223, 229, 247);
  color: rgb(37, 45, 67);
  cursor: pointer;
  padding: 0.45rem;
  margin: 0px 1.25rem 1.25rem;
  width: calc(100% - 2.5rem);
  border: none;
  font-size: 1rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.btn_footerShare {
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 0.75rem;
  display: block;
  width: 100%;
  border: 0px;
  font-size: 1rem;
  transition: 0.3s;
  background: rgb(0, 0, 0);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}

.copyLink_dv i {
  color: rgb(37, 45, 67);
  padding-right: 5px;
}

.dropdown-content.show {
  margin-top: 5px;
}

// mobile responsive share functionality css end

// resonsive css in cart details view only for mobile screen css - end

@media (max-width: 540px) {
  .chakra-container {
    background: #fff !important;
  }
}

@media (min-width: 1200px) {
  .chakra-container {
    padding: 0 120px !important;
  }

  .__mainCtrl:before {
    content: '';
    width: calc(100% + 240px);
    left: -120px;
  }

  .ownContainer_box {
    padding: 0 !important;
  }

  .header_header__MhatS .header_bottom_nav__hugTh {
    padding: 30px 120px;
  }
  .css-mr6tye {
    font-size: 14px !important;
  }
}

@media (max-width: 1200px) {
  .resp_tbl_box .css-13n17dq {
    flex-direction: column !important;
  }
  .resp_tbl_box .css-63rpde {
    width: 100%;
  }

  .ownContainer_box {
    min-width: 68% !important;
  }

  .res_tbl_tdx {
    width: 100% !important;
  }

  .css-mr6tye {
    font-size: 14px !important;
  }
}

@media (max-width: 767px) {
  .anvHide_overFlow::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .anvHide_overFlow {
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.swiper-pagination-bullet {
  background: #d9d9d9 !important;
  margin: 0 4px !important;
}

.swiper-pagination-bullet-active {
  background: #f41106 !important;
  width: 25px;
  border-radius: 5px;
}
