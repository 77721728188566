.header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1000; /* Ensure it's on top of other content */
  background-color: white; /* Ensure background is solid to cover content beneath */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
  @media (max-width: 767px) {
    box-shadow: none;
  }
  .top_nav {
    background: #f5f5f5;
    padding: 10px 120px;
    height: 38px;
    @media (max-width: 767px) {
      display: none;
    }
    .number {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px; /* 121.429% */
    }
    .off_text {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px; /* 121.429% */
    }
  }
  .logo {
    height: 35px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .bottom_nav {
    padding: 30px 10px;
    // margin-top: 10px;
    margin-bottom: 1px;
    background: #fff;
    height: 74px;
    z-index: 3;
    position: relative;
    box-shadow: 0 1px 1px #0000001a;
    @media (max-width: 767px) {
      box-shadow: none;
    }

    @media (max-width: 767px) {
      padding: 10px 10px;
      flex-direction: row;
      align-items: center;
      height: auto;
      justify-content: space-between;
      gap: 15px;
      .right__flex {
        flex-direction: column;
        width: 100%;
        gap: 15px;
        .input__group {
          width: 100%;
        }
      }
    }
    .burger_menu_icon {
      @media (max-width: 767px) {
        width: 15%;
      }
    }
  }
  .icons_menu_flex {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .notification_icon {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:focus {
        outline: none !important;
      }
    }
  }
}

.all_category {
  background: #fff;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.19);
  padding: 45px 0;
  position: absolute;
  z-index: 9;
  right: 0;
  left: 0;
  min-height: 460px;
  margin-top: -6px;
  @media (max-width: 767px) {
    .categories_title {
      display: none;
    }
    padding: 0 0;
    box-shadow: none;
    // .categories_parent {
    //   min-width: 130px;
    // }
    .categories_PARENT {
      overflow: hidden;
      padding-left: 5px;
    }
    .categories_child {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-right: 0;
      box-sizing: content-box;
      max-height: calc(100vh - 128px);
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 0;
  }

  .overlay {
    position: absolute;
    bottom: -60%;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.19);
    height: 60%;
  }
}

.suggestion_box {
  position: absolute;
  background: #fff;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.19);
  top: 48px;
  height: auto;
  z-index: 2;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  right: 0;
  .suggestion {
    cursor: pointer;
  }
}
