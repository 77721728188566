@media (max-width: 767px) {
  .sub_child_flex {
    display: none;
  }
  .sub_parent {
    overflow: hidden;
  }

  .sub_child {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 3px;
    box-sizing: content-box;
    max-height: calc(100vh - 65px);
    overflow: auto;
  }
  .sub_cat_name {
    font-size: 12px;
  }
  .sub_cat_item {
    height: 66px;
    width: 66px;
    .sub_cat_img {
      height: 60px;
      width: 60px;
      min-width: 60px;
    }
    .sub_cat_name {
      font-size: 11px;
      line-height: 15px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .sub_child {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 3px;
    box-sizing: content-box;
    max-height: 100%;
    overflow: auto;
  }
}
